import { ENVIRONMENT } from "shared/lib/constants/environment";

export const LANDING_OPENINGS_URL = {
  [ENVIRONMENT.LOCAL]: "http://localhost:3004/openings",
  [ENVIRONMENT.STAGING]: "https://landing-staging.glencoco.com/openings",
  [ENVIRONMENT.PRODUCTION]: "https://glencoco.com/openings",
};

export const USER_PLATFORM_URL = {
  [ENVIRONMENT.LOCAL]: "http://localhost:3000",
  [ENVIRONMENT.STAGING]: "https://user-staging.glencoco.com",
  [ENVIRONMENT.PRODUCTION]: "https://user.glencoco.com",
};

export const CUSTOMER_PLATFORM_URL = {
  [ENVIRONMENT.LOCAL]: "http://localhost:3001",
  [ENVIRONMENT.STAGING]: "https://customer-staging.glencoco.com",
  [ENVIRONMENT.PRODUCTION]: "https://customer.glencoco.com",
};

export const GLENX_LANDING_URL = {
  [ENVIRONMENT.LOCAL]: "http://localhost:3005",
  [ENVIRONMENT.STAGING]: "https://landing-staging.glenx.ai",
  [ENVIRONMENT.PRODUCTION]: "https://glenx.ai",
};

export const GLENX_PLATFORM_URL = {
  [ENVIRONMENT.LOCAL]: "http://localhost:3006",
  [ENVIRONMENT.STAGING]: "https://user-staging.glenx.ai",
  [ENVIRONMENT.PRODUCTION]: "https://user.glenx.ai",
};

export const HTTPS_REGEX = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

export const LINKEDIN_REGEX =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|public-profile\/in|public-profile\/pub)\/[\w-]+\/?$/;
